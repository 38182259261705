<script>
import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  components: { Icon },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    element: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
    headerElement: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filledElement: {},
    };
  },
  computed: {
    elementLabel() {
      return this.element ? this.element.label : this.placeholder;
    },
  },
  mounted() {
    this.filledElement = this.element;
  },
  methods: {
    updateRequired(event) {
      this.filledElement.required = event.target.checked;

      this.$eventBus.$emit('onElementChanged', this.stepId);
    },
    updateElement(event) {
      this.filledElement.label = event.target.value;

      this.$eventBus.$emit('onElementChanged', this.stepId);
    },
    onDelete() {
      this.$dialog.$delete({
        title: this.__('Delete step element'),
        contents: this.__(`Are you sure you want to delete this element?`),
        confirmButtonText: this.__('Delete'),
        cancelButtonText: this.__('Cancel'),
        type: 'danger',
        beforeClose: (action, _instance, done) => {
          if (action === 'confirm') {
            this.$eventBus.$emit('onElementRemoved', {
              stepElement: this.element,
              stepId: this.stepId,
            });
          }
          done();
        },
      });
    },
  },
});
</script>

<template>
  <div class="flex flex-col space-y-4">
    <div class="mt-1 flex space-x-4">
      <div
        class="component-dragger cursor-pointer rounded border border-athens-gray px-1 py-4"
      >
        <Icon class="rotate-90" name="drag" />
      </div>

      <trailblazer-textfield
        :value="__(elementLabel)"
        expand
        hide-label
        :disabled="disableLabel"
        @blur="updateElement($event)"
      />

      <slot name="extraHeadContent" />

      <div
        v-if="
          filledElement
            && filledElement.hasOwnProperty('required')
            && !headerElement
        "
        class="border-margin flex items-center justify-center space-x-4 p-4"
      >
        <span class="text-neutral-2">{{ __('Required') }}</span>
        <trailblazer-checkbox
          :checked="filledElement.required"
          hide-label
          @change="updateRequired($event)"
        />
      </div>

      <div
        class="border-margin flex cursor-pointer items-center justify-center p-4"
        @click="onDelete"
      >
        <trailblazer-icon accent="var(--tb-color-error)" name="close" />
      </div>
    </div>

    <div class="mt-1 flex flex-col space-y-4">
      <slot name="templateContent" />
    </div>
  </div>
</template>

<style scoped>
.border-margin {
  @apply rounded border border-neutral-3;
}
</style>
